import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_REPORT_LANG: {
  DOWNLOAD_ALL_TITLE: MvnCodeFE;
  DOWNLOAD_START: MvnCodeFE;
  DOWNLOAD_FAIL: MvnCodeFE;
  REPORT_PAGE_SUBTITLE: MvnCodeFE;
} = {
  DOWNLOAD_ALL_TITLE: {
    ko: "전체 다운로드",
    en: "Download All",
  },
  DOWNLOAD_START: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has been started.",
  },
  DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다.",
    en: "Failed to download.",
  },
  REPORT_PAGE_SUBTITLE: {
    ko: "룸 내에 사용자의 활동을 확인할 수 있습니다.",
    en: "Track all actions and changes in the room.",
  },
};

MvnCodeInitiate(VDR_ROOM_REPORT_LANG);
