import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_SUBSCRIPTION_LANG: {
  AUTH_SUBSCRIPTION_PAGE_DESCRIPTION: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_TITLE_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_TITLE_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_USER_PRICE: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_PRORATED: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_REFUND_LABEL: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DOCS_REFUND_1: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DOCS_REFUND_2: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_SAFETY_EXPORT_POINTS: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_SUBSCRIBE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_UPGRADE_CONFIRM_TITLE_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_UPGRADE_CONFIRM_TITLE_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_DIALOG_HEADER: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_BTN: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_PLACEHOLDER: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_DESC: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_ADD_LICENSE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_DIALOG_HEADER: MvnCodeFE;
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_BTN: MvnCodeFE;
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_DESC: MvnCodeFE;
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUBSCRIBE_DIALOG_HEADER: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUBSCRIBE_BTN: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUBSCRIBE_DESC: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUBSCRIBE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUBSCRIBE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_TITLE: MvnCodeFE;
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_CONFIRM: MvnCodeFE;
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_DESC: MvnCodeFE;
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUME_LICENSE_TITLE: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUME_LICENSE_CONFIRM: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUME_LICENSE_SUCC: MvnCodeFE;
  AUTH_SUBSCRIPTION_RESUME_LICENSE_FAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_STATUS_VALID: MvnCodeFE;
  AUTH_SUBSCRIPTION_STATUS_SUSPENDED: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_BEFORE_CREATE_ORG: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_GET_STARTED: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_CONTACT_SALES: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_UPGRADE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_MANAGE_LICENSE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SUBSCRIBING: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_DESCRIPTION: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_STORAGE_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_STORAGE_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_ARCHIVER_AVAILABLE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNIT: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_CI: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_REQUEST: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_SHARE_ORG: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_SHARE_EXT: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_IP_WHITELIST: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_WATERMARK: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_COMPOSER: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_LIBRARY: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS_PREFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS_SUFFIX: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS: MvnCodeFE;
  AUTH_SUBSCRIPTION_EMPTY_ORGANIZATION: MvnCodeFE;
  AUTH_SUBSCRIPTION_EMPTY_ORGANIZATION_GO: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_FAVORITE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_IMPORT: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_EXPORT: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_AUDIT_TRAIL: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_REQUEST_APPROVE_BLIND: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_SUBMISSION: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_SUBMISSION_DUE_DATE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_MEMBER_MANAGE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DATABASE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DASHBOARD: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_CUSTOMIZE_REPORT_FORM: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_OTHER_EDC_INTEGRATION: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_ENTERPRISE_PLAN: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_ENTERPRISE_PLUS_PLAN: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DESCRIPTION: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_OVERPRICE: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_QUANTITY: MvnCodeFE;
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_QUANTITY_UNLIMITED: MvnCodeFE;
} = {
  AUTH_SUBSCRIPTION_PAGE_DESCRIPTION: {
    ko: "구독 상태 관리",
    en: "Manage subscription status",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_TITLE_PREFIX: {
    ko: "'",
    en: "Are you sure you want to subscribe to the '",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_TITLE_SUFFIX: {
    ko: "'을 구독하시겠습니까?",
    en: "'?",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_PREFIX: {
    ko: "1 License(",
    en: "1 License(",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_USER_PRICE: {
    ko: "계약금액",
    en: "Contract Price",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_SUFFIX: {
    ko: ")가 즉시 시작됩니다.",
    en: ") is starts immediately.",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DESCRIPTION_PRORATED: {
    ko: ", 비례계산",
    en: ", Prorated Amount",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_REFUND_LABEL: {
    ko: "환불 규정",
    en: "Refund Policy",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DOCS_REFUND_1: {
    ko: "서비스 이용내역이 없는 경우, 결제 시점 기준으로 7일 내로 전액 환불이 가능합니다.",
    en: "If there is no usage history, you can get a full refund within 7 days from the time of payment.",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_DOCS_REFUND_2: {
    ko: "서비스 이용내역이 있는 경우, 환불이 불가하며 현재 구독 기간이 종료될 때까지 서비스 이용이 가능합니다.",
    en: "If there is a usage history, there are no refunds. You will be able to use the service until the end of your current subscription period.",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_CONFIRM_SAFETY_EXPORT_POINTS: {
    ko: "개별 구매한 XML export의 유효기간은 유지되며 사라지지 않습니다.",
    en: "Expiration date of individually purchased points will be preserved.",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_SUCC: {
    ko: "Plan 구독을 시작하였습니다.",
    en: "You have subscribed a plan successfully.",
  },
  AUTH_SUBSCRIPTION_SUBSCRIBE_FAIL: {
    ko: "Plan 구독에 실패하였습니다. ({message})",
    en: "Failed to subscribe plan. ({message})",
  },
  AUTH_SUBSCRIPTION_UPGRADE_CONFIRM_TITLE_PREFIX: {
    ko: "'{before}'에서 '",
    en: "Are you sure you want to upgrade plan to the '",
  },
  AUTH_SUBSCRIPTION_UPGRADE_CONFIRM_TITLE_SUFFIX: {
    ko: "'으로 변경하시겠습니까?",
    en: "' from '{before}'?",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_DIALOG_HEADER: {
    ko: "Add License",
    en: "Add License",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_BTN: {
    ko: "Add License",
    en: "Add License",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_PLACEHOLDER: {
    ko: "License를 부여할 Member를 선택해 주세요.",
    en: "Please choose a member to add license.",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_DESC: {
    ko: "선택한 Member에게 1 License({price})를 추가 하시겠습니까?",
    en: "Are you sure you want to add 1 License({price}) to a selected member?",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_SUCC: {
    ko: "License를 추가하였습니다.",
    en: "You have added a license successfully.",
  },
  AUTH_SUBSCRIPTION_ADD_LICENSE_FAIL: {
    ko: "License 추가에 실패하였습니다. ({message})",
    en: "Failed to add license. ({message})",
  },
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_DIALOG_HEADER: {
    ko: "구독해지",
    en: "Unsubscribe",
  },
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_BTN: {
    ko: "구독해지",
    en: "Unsubscribe",
  },
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_DESC: {
    ko: "구독을 해지하시겠습니까?\n\n{date} 까지 계속 이용하실 수 있으며, 자동으로 구독이 해지됩니다.",
    en: "Are you sure you want to unsubscribe?\n\nYour license available until {date} and will automatically unsubscribe.",
  },
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_SUCC: {
    ko: "구독을 해지하였습니다.",
    en: "You have scheduled to unsubscribe license successfully.",
  },
  AUTH_SUBSCRIPTION_UNSUBSCRIBE_FAIL: {
    ko: "구독 해지에 실패하였습니다. ({message})",
    en: "Failed to unsubscribe license. ({message})",
  },
  AUTH_SUBSCRIPTION_RESUBSCRIBE_DIALOG_HEADER: {
    ko: "구독재개",
    en: "Resume Subscription",
  },
  AUTH_SUBSCRIPTION_RESUBSCRIBE_BTN: {
    ko: "구독재개",
    en: "Resume Subscription",
  },
  AUTH_SUBSCRIPTION_RESUBSCRIBE_DESC: {
    ko: "구독을 유지하시겠습니까?\n\n다음 결제일({date})에 라이센스가 자동 연장됩니다.",
    en: "Are you sure you want to resubscribe?\n\nYour license will be automatically paid on the next payment day({date}).",
  },
  AUTH_SUBSCRIPTION_RESUBSCRIBE_SUCC: {
    ko: "구독을 재개하였습니다.",
    en: "You have cancelled scheduled to unsubscribe successfully.",
  },
  AUTH_SUBSCRIPTION_RESUBSCRIBE_FAIL: {
    ko: "구독 재개에 실패하였습니다. ({message})",
    en: "Failed to resubscribe license. ({message})",
  },
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_TITLE: {
    ko: "License 삭제",
    en: "License Remove",
  },
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_CONFIRM: {
    ko: "선택하신 사용자의 License를 삭제하시겠습니까?",
    en: "Are you sure you want to remove a license from the selected user?",
  },
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_DESC: {
    ko: "사용자의 License는 다음 결제일에 자동으로 삭제됩니다.",
    en: "User license will be automatically deleted on the next payment date.",
  },
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_SUCC: {
    ko: "License를 삭제하였습니다.",
    en: "You have removed license successfully.",
  },
  AUTH_SUBSCRIPTION_REMOVE_LICENSE_FAIL: {
    ko: "License 삭제에 실패하였습니다. ({message})",
    en: "The license removal request has been failed. ({message})",
  },
  AUTH_SUBSCRIPTION_RESUME_LICENSE_TITLE: {
    ko: "License 재개",
    en: "License Resume",
  },
  AUTH_SUBSCRIPTION_RESUME_LICENSE_CONFIRM: {
    ko: "선택하신 사용자의 License를 재개하시겠습니까?",
    en: "Are you sure you want to resume a license to the selected user?",
  },
  AUTH_SUBSCRIPTION_RESUME_LICENSE_SUCC: {
    ko: "License를 재개하였습니다.",
    en: "You have resumed license successfully.",
  },
  AUTH_SUBSCRIPTION_RESUME_LICENSE_FAIL: {
    ko: "License 재개에 실패하였습니다. ({message})",
    en: "The license resume request has been failed. ({message})",
  },
  AUTH_SUBSCRIPTION_STATUS_VALID: {
    ko: "이용 중",
    en: "Valid",
  },
  AUTH_SUBSCRIPTION_STATUS_SUSPENDED: {
    ko: "취소 예약 중",
    en: "Pending Cancellation",
  },
  AUTH_SUBSCRIPTION_BUNDLE_BEFORE_CREATE_ORG: {
    ko: "제품을 구독하기 전, Organization을 먼저 생성해 주세요.",
    en: "Please create an organization first to subscribe to products.",
  },
  AUTH_SUBSCRIPTION_BUNDLE_GET_STARTED: {
    ko: "시작하기",
    en: "Get Started",
  },
  AUTH_SUBSCRIPTION_BUNDLE_CONTACT_SALES: {
    ko: "세일즈 문의",
    en: "Contact Sales",
  },
  AUTH_SUBSCRIPTION_BUNDLE_UPGRADE: {
    ko: "플랜 변경",
    en: "Upgrade Plan",
  },
  AUTH_SUBSCRIPTION_BUNDLE_MANAGE_LICENSE: {
    ko: "라이센스 관리",
    en: "Manage Licenses",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SUBSCRIBING: {
    ko: "구독중",
    en: "Subscribing",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_DESCRIPTION: {
    ko: "21 CFR Part 11 규정을 충족하는 임상시험 문서에 최적화된 저작 및 전자서명 솔루션",
    en: "Ultimate Document Composing and E-Signing Solution comply with 21 CFR Part 11",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_STORAGE_PREFIX: {
    ko: "My Documents 저장 공간",
    en: "My Documents Storage",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_STORAGE_SUFFIX: {
    ko: "/사용자",
    en: "/user",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_PREFIX: {
    ko: "Archiver 저장 공간",
    en: "Archiver Storage",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_SUFFIX: {
    ko: "/조직",
    en: "/organization",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ARCHIVER_STORAGE_ARCHIVER_AVAILABLE: {
    ko: "Archiver 사용 여부 선택",
    en: "Choose Whether Or Not to Use Archiver",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_PREFIX: {
    ko: "전자서명 최대",
    en: "E-Sign up to",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNIT: {
    ko: "건 요청",
    en: "requests",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_SUFFIX: {
    ko: "/사용자",
    en: "/user",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_CI: {
    ko: "본인인증을 통한 전자서명 보호",
    en: "Protect E-Sign with Personal Identification",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_PREFIX: {
    ko: "전자서명 ",
    en: "E-Sign ",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_REQUEST: {
    ko: "무제한",
    en: "Unlimited",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ESIGN_UNLIMITED_SUFFIX: {
    ko: " 요청",
    en: " requests",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_SHARE_ORG: {
    ko: "조직 내부 사용자와의 문서 공유",
    en: "Document Sharing between Organization Members",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_SHARE_EXT: {
    ko: "조직 외부 사용자와의 문서 공유",
    en: "Document Sharing with Outside Collaborator",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_IP_WHITELIST: {
    ko: "IP Whitelist 기반의 문서 접근 제어",
    en: "Document Access Control via IP Whitelist",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_WATERMARK: {
    ko: "동적 워터마크 기능을 이용한 안전한 문서 공유",
    en: "Secure Document Sharing with Dynamic Watermark",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_COMPOSER: {
    ko: "온라인 문서 저작 도구 제공",
    en: "Online Document Composing Tool",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_LIBRARY: {
    ko: "문서 라이브러리",
    en: "Document Library",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS_PREFIX: {
    ko: "전자동의서(eConsent) ",
    en: "eConsent ",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS_SUFFIX: {
    ko: " 요청",
    en: " requests",
  },
  AUTH_SUBSCRIPTION_BUNDLE_DOCS_ECONSENT_LIMITLESS: {
    ko: "무제한",
    en: "Unlimited",
  },
  AUTH_SUBSCRIPTION_EMPTY_ORGANIZATION: {
    ko: "생성된 Organization이 없습니다.\nPlan을 구독하기 위해서 Organization을 먼저 생성해 주세요.",
    en: "No organizations have been created.\nCreate organization first.",
  },
  AUTH_SUBSCRIPTION_EMPTY_ORGANIZATION_GO: {
    ko: "Organization 생성하기",
    en: "Create an Organization",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DATABASE: {
    ko: "Safety Database 제공",
    en: "Safety database management",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DASHBOARD: {
    ko: "Dashboard 기능 제공(예정)",
    en: "Case report dashboard (TBD)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_CUSTOMIZE_REPORT_FORM: {
    ko: "Customized import 양식 제공(1회 제공, 추가시 별도 비용 발생)",
    en: "Customized import form provided (one free form, additional cost required for more)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_OTHER_EDC_INTEGRATION: {
    ko: "타 EDC 연동 가능 (별도 비용 발생, 예정)",
    en: "Linkage with other EDC (setup cost required)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_ENTERPRISE_PLAN: {
    ko: "Ready-to-Go의 모든 기능",
    en: "All features of Ready-to-Go",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_ENTERPRISE_PLUS_PLAN: {
    ko: "Enterprise의 모든 기능",
    en: "All features of Enterprise",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE: {
    ko: "최대 {number}개의 Workspace 제공",
    en: "Provides up to {number} Workspaces",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_FAVORITE: {
    ko: "자주 입력하는 정보에 대한 즐겨찾기 기능 제공(예정)",
    en: "Bookmark function for frequently used information (TBD)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_IMPORT: {
    ko: "Case Import 기능 제공",
    en: "Case Import",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_EXPORT: {
    ko: "XML export 기능 제공",
    en: "Case export in XML format",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_AUDIT_TRAIL: {
    ko: "Audit Trail 제공",
    en: "Data audit trail",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_CASE_REQUEST_APPROVE_BLIND: {
    ko: "Approve / Blind 기능 제공",
    en: "Case approve / blind process",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_MEMBER_MANAGE: {
    ko: "Member 별 권한 관리 기능 제공",
    en: "Member managements with role / privileges",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_SUBMISSION: {
    ko: "식약처 보고기능 (예정)",
    en: "Direct reporting for Ministry of Food and Drug Safety (TBD)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_WORKSPACE_SUBMISSION_DUE_DATE: {
    ko: "Due date 설정 기능 제공",
    en: "Data management with due date settings",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_DESCRIPTION: {
    ko: "E2B(R3) 전자보고 및 Safety Database 관리 솔루션",
    en: "E2B(R3) electronic reporting and safety database management solution",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_OVERPRICE: {
    ko: "({quantity} case 초과시)",
    en: "(In case of exceeding {quantity} cases)",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_QUANTITY: {
    ko: "{quantity} cases",
    en: "{quantity} cases",
  },
  AUTH_SUBSCRIPTION_BUNDLE_SAFETY_XML_QUANTITY_UNLIMITED: {
    ko: "무제한",
    en: "Unlimited",
  },
};

MvnCodeInitiate(AUTH_SUBSCRIPTION_LANG);
