import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DATA_INTEGRATION_CODE: {
  NOT_FOUND: MvnCodeBE;
  DENIED_BY_PRIV: MvnCodeBE;
  VALIDATE_ERROR_STUDY_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_AUDIT_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_SPEC_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_CRITERIA_ROW_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_SUBJECT_COLUMN_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_EMPTY_COLUMN: MvnCodeBE;
  VALIDATE_ERROR_SUBJECT_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_SUBJECT_SITE_NOT_AUTHORIZED: MvnCodeBE;
  VALIDATE_ERROR_ITEM_SEQ_ONLY_NUMBER_ENABLE: MvnCodeBE;
  VALIDATE_ERROR_VISIT_NOT_DEFINED: MvnCodeBE;
  VALIDATE_ERROR_VISIT_CODE_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_TARGET_CRF_PAGE_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_TARGET_ITEM_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_ALIAS_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_MISSING_ATTRIBUTE: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_FOLDER_ID_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_VISIT_ID_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_CRF_PAGE_ID_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_SECTION_ID_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_ITEM_ID_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_STRIKED: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_CAPTURE_NOT_AVAILABLE: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_MAPPING_VALUE_NOT_EXIST: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_DATE_VALUE_INVALID: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: MvnCodeBE;
  VALIDATE_ERROR_BIND_TO_VALUE_CODE_NOT_MATCHED: MvnCodeBE;
  VALIDATE_ERROR_ASSIGN_VALUE_DUPLICATED: MvnCodeBE;
  VALIDATE_ERROR_ASSIGN_VALUE_MODIFY_DISABLE: MvnCodeBE;
  VALIDATE_ERROR_INTEGRATION_ITEM_SEQ_INVALID: MvnCodeBE;
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_NOT_SELECTED: MvnCodeBE;
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_FORMAT: MvnCodeBE;
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_REFERENCE: MvnCodeBE;
  VALIDATE_ERROR_INTEGRATION_AVAILABILITY_INVALID: MvnCodeBE;
  VALIDATE_ERROR_FOLDER_NOT_DEFINED: MvnCodeBE;
} = {
  NOT_FOUND: {
    status: 404,
    ko: "Study Data Integration 정보가 존재하지 않습니다.",
    en: "Study data integration entity does not exist.",
  },
  DENIED_BY_PRIV: {
    status: 403,
    ko: "요청하신 정보의 Download 권한이 없습니다.",
    en: "No access granted to download privilege.",
  },
  VALIDATE_ERROR_STUDY_NOT_EXIST: {
    status: 400,
    ko: "Study 미존재 ({studyId})",
    en: "Study does not exist ({studyId})",
  },
  VALIDATE_ERROR_AUDIT_NOT_EXIST: {
    status: 400,
    ko: "Integration audit 설정 미존재",
    en: "Integration audit settings not present",
  },
  VALIDATE_ERROR_SPEC_NOT_EXIST: {
    status: 400,
    ko: "Data Integration Spec 미존재 ({identifier})",
    en: "Data integration spec does not exist ({identifier})",
  },
  VALIDATE_ERROR_CRITERIA_ROW_NOT_EXIST: {
    status: 400,
    ko: "Integration 기준 Row 미존재",
    en: "Integration criteria row does not exist",
  },
  VALIDATE_ERROR_SUBJECT_COLUMN_NOT_EXIST: {
    status: 400,
    ko: "Subject 식별 컬럼 미존재",
    en: "Subject identity column does not exist",
  },
  VALIDATE_ERROR_EMPTY_COLUMN: {
    status: 400,
    ko: "필수 입력 값 누락",
    en: "Missing required input value",
  },
  VALIDATE_ERROR_SUBJECT_NOT_EXIST: {
    status: 400,
    ko: "대상자 없음 ({subjectNo})",
    en: "Subject Not Exists ({subjectNo})",
  },
  VALIDATE_ERROR_SUBJECT_SITE_NOT_AUTHORIZED: {
    status: 400,
    ko: "미허가 Site 소속 Subject ({subjectNo})",
    en: "Subject ({subjectNo}) belonging to an unauthorized site",
  },
  VALIDATE_ERROR_ITEM_SEQ_ONLY_NUMBER_ENABLE: {
    status: 400,
    ko: "Item Seq 값이 숫자가 아님 ({itemSeq})",
    en: "Item Seq value is not numeric ({itemSeq})",
  },
  VALIDATE_ERROR_VISIT_NOT_DEFINED: {
    status: 400,
    ko: "정의되지 않은 값 ({visitKey})",
    en: "Undefined value ({visitKey})",
  },
  VALIDATE_ERROR_VISIT_CODE_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Visit Code ({visitKey})",
    en: "Non-existent Visit Code ({visitKey})",
  },
  VALIDATE_ERROR_TARGET_CRF_PAGE_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 CRF Page ({crfPageId})",
    en: "Non-existent CRF Page ({crfPageId})",
  },
  VALIDATE_ERROR_TARGET_ITEM_NOT_EXIST: {
    status: 400,
    ko: "CRF Page 내 정의되지 않은 값 ({target})",
    en: "Undefined value in CRF Page ({target})",
  },
  VALIDATE_ERROR_BIND_TO_ALIAS_NOT_EXIST: {
    status: 400,
    ko: "Alias 값 미존재 ({alias})",
    en: "Alias value not present ({alias})",
  },
  VALIDATE_ERROR_BIND_TO_MISSING_ATTRIBUTE: {
    status: 400,
    ko: "일부 설정 값 누락으로 인한 검증 진행 불가",
    en: "Values cannot be verified because some setting values are missing",
  },
  VALIDATE_ERROR_BIND_TO_FOLDER_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Folder ID ({folderId})",
    en: "Non-existent Folder ID ({folderId})",
  },
  VALIDATE_ERROR_BIND_TO_VISIT_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Visit ID ({visitId})",
    en: "Non-existent Visit ID ({visitId})",
  },
  VALIDATE_ERROR_BIND_TO_CRF_PAGE_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 CRF Page ID ({crfPageId})",
    en: "Non-existent CRF Page ID ({crfPageId})",
  },
  VALIDATE_ERROR_BIND_TO_SECTION_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Section ID ({sectionUuid})",
    en: "Non-existent Section ID ({sectionUuid})",
  },
  VALIDATE_ERROR_BIND_TO_ITEM_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Item ID ({itemId})",
    en: "Non-existent Item ID ({itemId})",
  },
  VALIDATE_ERROR_BIND_TO_STRIKED: {
    status: 400,
    ko: "취소선이 적용된 항목 ({bindTo})",
    en: "Crossed out items ({bindTo})",
  },
  VALIDATE_ERROR_BIND_TO_CAPTURE_NOT_AVAILABLE: {
    status: 400,
    ko: "데이터 저장 불가 상태 ({bindTo})",
    en: "Unsaveable data status ({bindTo})",
  },
  VALIDATE_ERROR_BIND_TO_MAPPING_VALUE_NOT_EXIST: {
    status: 400,
    ko: "정의되지 않은 Mapping 값 ({value})",
    en: "Undefined Mapping Value ({value})",
  },
  VALIDATE_ERROR_INTEGRATION_ITEM_SEQ_INVALID: {
    status: 400,
    ko: "연속적이지 않은 행 (누락된 행: {seq})",
    en: "Non-consecutive Rows (Missing Row Seq.: {seq})",
  },
  VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: {
    status: 400,
    ko: "잘못된 날짜 형식 ({value})",
    en: "Invalid date format ({value})",
  },
  VALIDATE_ERROR_BIND_TO_DATE_VALUE_INVALID: {
    status: 400,
    ko: "범위를 초과한 날짜 ({range})",
    en: "Date out of range ({range})",
  },
  VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: {
    status: 400,
    ko: "올바르지 않은 데이터 입력 형식 ({value})",
    en: "Invalid data entry format ({value})",
  },
  VALIDATE_ERROR_BIND_TO_VALUE_CODE_NOT_MATCHED: {
    status: 400,
    ko: "일치하는 code 미존재 ({value})",
    en: "Matched code not exist ({value})",
  },
  VALIDATE_ERROR_ASSIGN_VALUE_DUPLICATED: {
    status: 400,
    ko: "중복된 {assignNo} 부여 ({value})",
    en: "Duplicate {assignNo} assign ({value})",
  },
  VALIDATE_ERROR_ASSIGN_VALUE_MODIFY_DISABLE: {
    status: 400,
    ko: "{assignNo} 변경 불가 ({value})",
    en: "{assignNo} cannot be changed ({value})",
  },
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_NOT_SELECTED: {
    status: 400,
    ko: "선택되지 않은 값 입력 불가",
    en: "Unselected values cannot be entered",
  },
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_FORMAT: {
    status: 400,
    ko: "잘못된 형식 ({value})",
    en: "Invalid format ({value})",
  },
  VALIDATE_ERROR_INTEGRATION_TYPE_TEXT_INVALID_REFERENCE: {
    status: 400,
    ko: "잘못된 값 참조 ({value})",
    en: "Invalid value reference ({value})",
  },
  VALIDATE_ERROR_INTEGRATION_AVAILABILITY_INVALID: {
    status: 400,
    ko: "비활성화 된 항목",
    en: "Disabled item",
  },
  VALIDATE_ERROR_FOLDER_NOT_DEFINED: {
    status: 400,
    ko: "정의되지 않은 Folder ID 값 ({folderId})",
    en: "Undefined Folder ID value ({folderId})",
  },
};

export const STUDY_DATA_INTEGRATION_UPLOAD_CODE: {
  NOT_FOUND: MvnCodeBE;
  NOT_AVAILABLE: MvnCodeBE;
  FILE_NOT_EXISTS: MvnCodeBE;
} = {
  NOT_FOUND: {
    status: 404,
    ko: "Study Data Integration Upload 정보가 존재하지 않습니다.",
    en: "Study data integration upload entity does not exist.",
  },
  NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Data Integration Upload({id}) 정보가 올바르지 않습니다.",
    en: "Requested study data integration upload({id}) is not valid.",
  },
  FILE_NOT_EXISTS: {
    status: 400,
    ko: "Study Data Integration Upload File이 존재하지 않습니다.",
    en: "Study data integration upload file dose not exist.",
  },
};

MvnCodeInitiate(STUDY_DATA_INTEGRATION_CODE);
MvnCodeInitiate(STUDY_DATA_INTEGRATION_UPLOAD_CODE);
