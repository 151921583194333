import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_COMMENT_CODE: {
  NOTFOUND: MvnCodeBE;
  STUDY_COMMENT_MESSAGE_EXCEED_MAXLEN: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "Study Comment 정보가 존재하지 않습니다.",
    en: "Study comment entity does not exist.",
  },
  STUDY_COMMENT_MESSAGE_EXCEED_MAXLEN: {
    status: 400,
    ko: "Study Comment Message가 {maxLen}자를 초과했습니다.",
    en: "The comment exceeds the maximum length of {maxLen} characters.",
  },
};

MvnCodeInitiate(STUDY_COMMENT_CODE);
