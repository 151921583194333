import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_COMMON_LANG: {
  ROOM_NOUN: MvnCodeFE;
  COMPANY_NOUN: MvnCodeFE;
  LOGIN_NOUN: MvnCodeFE;
  ACTIVE_NOUN: MvnCodeFE;
  CLOSED_NOUN: MvnCodeFE;
  GENERAL_NOUN: MvnCodeFE;
  SETTING_NOUN: MvnCodeFE;
  SAVE_NOUN: MvnCodeFE;
  DOCUMENT_NOUN: MvnCodeFE;
  FOLDER_NOUN: MvnCodeFE;
  INDEX_NOUN: MvnCodeFE;
  FAVORITE_NOUN: MvnCodeFE;
  TRASH_NOUN: MvnCodeFE;
  NAME_NOUN: MvnCodeFE;
  TYPE_NOUN: MvnCodeFE;
  STATUS_NOUN: MvnCodeFE;
  SIZE_NOUN: MvnCodeFE;
  UPLOADED_BY_NOUN: MvnCodeFE;
  UPLOADED_AT_NOUN: MvnCodeFE;
  FILE_NOUN: MvnCodeFE;
  UNPUBLISHED_NOUN: MvnCodeFE;
  PARTIAL_PUBLISHED_NOUN: MvnCodeFE;
  PUBLISHED_NOUN: MvnCodeFE;
  DOWNLOAD_NOUN: MvnCodeFE;
  CURRENT_NOUN: MvnCodeFE;
  FAIL_NOUN: MvnCodeFE;
  MOVE_NOUN: MvnCodeFE;
  PRINT_NOUN: MvnCodeFE;
  QNA_NOUN: MvnCodeFE;
  CATEGORY_NOUN: MvnCodeFE;
  LAST_UPDATED_NOUN: MvnCodeFE;
  GROUP_OR_USER_NOUN: MvnCodeFE;
  ROLE_OR_EMAIL_NOUN: MvnCodeFE;
  LAST_LOGIN_NOUN: MvnCodeFE;
  DOCUMENT_LIST_NOUN: MvnCodeFE;
  OWNER_NOUN: MvnCodeFE;
  WATERMARK_NOUN: MvnCodeFE;
  USER_NOUN: MvnCodeFE;
  ACTION_NOUN: MvnCodeFE;
  DATE_AND_TIME_NOUN: MvnCodeFE;
  OPACITY_NOUN: MvnCodeFE;
  ITEM_NOUN: MvnCodeFE;
  POSITION_NOUN: MvnCodeFE;
  COPY_NOUN: MvnCodeFE;
  SELECT_NOUN: MvnCodeFE;

  // VERB
  ADD_VERB: MvnCodeFE;
  CANCEL_VERB: MvnCodeFE;
  CREATE_VERB: MvnCodeFE;
  DELETE_VERB: MvnCodeFE;
  SEARCH_VERB: MvnCodeFE;
  UPLOAD_VERB: MvnCodeFE;
  DOWNLOAD_VERB: MvnCodeFE;
  LEAVE_VERB: MvnCodeFE;
  CONFIRM_VERB: MvnCodeFE;
  INVITE_VERB: MvnCodeFE;

  // Validation
  REQUIRED_FIELD_EMPTY_VALIDATION: MvnCodeFE;
  ONLY_NUMBER_VALIDATION: MvnCodeFE;
  ONLY_POSITIVE_NUMBER_VALIDATION: MvnCodeFE;
  UNAUTHORIZED_ACCESS_VALIDATION_TITLE: MvnCodeFE;
  UNAUTHORIZED_ACCESS_VALIDATION_DESCRIPTION: MvnCodeFE;

  // Description
  TABLE_ROW_SELECT_DESCRIPTION: MvnCodeFE;

  // Menu
  MANAGE_QNA: MvnCodeFE;
  MANAGE_USER: MvnCodeFE;
  MANAGE_SETTING: MvnCodeFE;
} = {
  // NOUN
  ROOM_NOUN: {
    ko: "Room",
    en: "Room",
  },
  COMPANY_NOUN: {
    ko: "Company",
    en: "Company",
  },
  LOGIN_NOUN: {
    ko: "로그인",
    en: "Sign In",
  },
  ACTIVE_NOUN: {
    ko: "Active",
    en: "Active",
  },
  CLOSED_NOUN: {
    ko: "Closed",
    en: "Closed",
  },
  GENERAL_NOUN: {
    ko: "General",
    en: "General",
  },
  SETTING_NOUN: {
    ko: "Setting",
    en: "Setting",
  },
  SAVE_NOUN: {
    ko: "Save",
    en: "Save",
  },
  DOCUMENT_NOUN: {
    ko: "Document",
    en: "Document",
  },
  FOLDER_NOUN: {
    ko: "Folder",
    en: "Folder",
  },
  INDEX_NOUN: {
    ko: "Index",
    en: "Index",
  },
  FAVORITE_NOUN: {
    ko: "Favorite",
    en: "Favorite",
  },
  TRASH_NOUN: {
    ko: "Trash",
    en: "Trash",
  },
  NAME_NOUN: {
    ko: "Name",
    en: "Name",
  },
  TYPE_NOUN: {
    ko: "Type",
    en: "Type",
  },
  STATUS_NOUN: {
    ko: "Status",
    en: "Status",
  },
  SIZE_NOUN: {
    ko: "Size",
    en: "Size",
  },
  UPLOADED_BY_NOUN: {
    ko: "Uploaded By",
    en: "Uploaded By",
  },
  UPLOADED_AT_NOUN: {
    ko: "Uploaded",
    en: "Uploaded",
  },
  FILE_NOUN: {
    ko: "File",
    en: "File",
  },
  UNPUBLISHED_NOUN: {
    ko: "Unpublished",
    en: "Unpublished",
  },
  PARTIAL_PUBLISHED_NOUN: {
    ko: "Partial Published",
    en: "Partial Published",
  },
  PUBLISHED_NOUN: {
    ko: "Published",
    en: "Published",
  },
  DOWNLOAD_NOUN: {
    ko: "Download",
    en: "Download",
  },
  CURRENT_NOUN: {
    ko: "Current",
    en: "Current",
  },
  FAIL_NOUN: {
    ko: "Fail",
    en: "Fail",
  },
  MOVE_NOUN: {
    ko: "Move",
    en: "Move",
  },
  PRINT_NOUN: {
    ko: "Print",
    en: "Print",
  },
  QNA_NOUN: {
    ko: "Q&A",
    en: "Q&A",
  },
  CATEGORY_NOUN: {
    ko: "Category",
    en: "Category",
  },
  LAST_UPDATED_NOUN: {
    ko: "Last Updated",
    en: "Last Updated",
  },
  GROUP_OR_USER_NOUN: {
    ko: "Group / User",
    en: "Group / User",
  },
  ROLE_OR_EMAIL_NOUN: {
    ko: "Role / Email",
    en: "Role / Email",
  },
  LAST_LOGIN_NOUN: {
    ko: "Last Login",
    en: "Last Login",
  },
  DOCUMENT_LIST_NOUN: {
    ko: "Document List",
    en: "Document List",
  },
  OWNER_NOUN: {
    ko: "Owner",
    en: "Owner",
  },
  WATERMARK_NOUN: {
    ko: "Watermark",
    en: "Watermark",
  },
  USER_NOUN: {
    ko: "User",
    en: "User",
  },
  ACTION_NOUN: {
    ko: "Action",
    en: "Action",
  },
  DATE_AND_TIME_NOUN: {
    ko: "Date and Time",
    en: "Date and Time",
  },
  OPACITY_NOUN: {
    ko: "투명도",
    en: "Opacity",
  },
  ITEM_NOUN: {
    ko: "아이템",
    en: "Item",
  },
  POSITION_NOUN: {
    ko: "배열",
    en: "Position",
  },
  COPY_NOUN: {
    ko: "복사",
    en: "Copy",
  },
  SELECT_NOUN: {
    ko: "선택",
    en: "Select",
  },

  // VERB
  ADD_VERB: {
    ko: "추가",
    en: "Add",
  },
  CANCEL_VERB: {
    ko: "취소",
    en: "Cancel",
  },
  CREATE_VERB: {
    ko: "생성",
    en: "Create",
  },
  DELETE_VERB: {
    ko: "삭제",
    en: "Delete",
  },
  SEARCH_VERB: {
    ko: "검색",
    en: "Search",
  },
  UPLOAD_VERB: {
    ko: "업로드",
    en: "Upload",
  },
  DOWNLOAD_VERB: {
    ko: "다운로드",
    en: "Download",
  },
  LEAVE_VERB: {
    ko: "나가기",
    en: "Leave",
  },
  CONFIRM_VERB: {
    ko: "확인",
    en: "Confirm",
  },
  INVITE_VERB: {
    ko: "초대",
    en: "Invite",
  },

  // Description
  TABLE_ROW_SELECT_DESCRIPTION: {
    ko: "{count}개 선택됨",
    en: "{count} items are selected",
  },

  // validation
  REQUIRED_FIELD_EMPTY_VALIDATION: {
    ko: "필수 입력 항목입니다.",
    en: "This field is required.",
  },
  ONLY_NUMBER_VALIDATION: {
    ko: "숫자만 입력이 가능합니다.",
    en: "Only number is allowed.",
  },
  ONLY_POSITIVE_NUMBER_VALIDATION: {
    ko: "0 이상의 숫자만 입력이 가능합니다.",
    en: "Only positive number is allowed.",
  },
  UNAUTHORIZED_ACCESS_VALIDATION_TITLE: {
    ko: "페이지를 사용할 권한이 없습니다.",
    en: "User does not have privileges for this page.",
  },
  UNAUTHORIZED_ACCESS_VALIDATION_DESCRIPTION: {
    ko: "아래의 버튼을 눌러 문서함으로 이동해 주세요.",
    en: "Please click the [Go Documents] button below to be directed to Documents page.",
  },

  // Menu
  MANAGE_QNA: {
    ko: "Manage Q&A",
    en: "Manage Q&A",
  },
  MANAGE_USER: {
    ko: "Manage User",
    en: "Manage User",
  },
  MANAGE_SETTING: {
    ko: "Manage Setting",
    en: "Manage Setting",
  },
};

MvnCodeInitiate(VDR_COMMON_LANG);
