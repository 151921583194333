import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_COMMENT_LANG: {
  WRITE_SUCC: MvnCodeFE;
  WRITE_FAIL: MvnCodeFE;
  LIST_DIALOG_HEADER: MvnCodeFE;
  WRITE_DIALOG_BTN_CONFIRM: MvnCodeFE;
  WRITE_INPUT_LABEL: MvnCodeFE;
  WRITE_INPUT_PLACEHOLDER: MvnCodeFE;
  EDIT_SUCC: MvnCodeFE;
  EDIT_FAIL: MvnCodeFE;
  EDIT_DIALOG_HEADER: MvnCodeFE;
  EDIT_DIALOG_BTN_CONFIRM: MvnCodeFE;
} = {
  LIST_DIALOG_HEADER: {
    ko: "Comments",
    en: "Comments",
  },
  WRITE_SUCC: {
    ko: "Comment를 작성하였습니다.",
    en: "Comment has been wrote successfully.",
  },
  WRITE_FAIL: {
    ko: "Comment 작성에 실패하였습니다. ({message})",
    en: "Failed to write comment. ({message})",
  },
  WRITE_DIALOG_BTN_CONFIRM: {
    ko: "Comment",
    en: "Comment",
  },
  WRITE_INPUT_LABEL: {
    ko: "Comment 작성",
    en: "Write Comment",
  },
  WRITE_INPUT_PLACEHOLDER: {
    ko: "Comment 내용을 입력해 주세요.",
    en: "Please enter comment.",
  },
  EDIT_SUCC: {
    ko: "Comment를 수정하였습니다.",
    en: "Comment has been edited successfully.",
  },
  EDIT_FAIL: {
    ko: "Comment 수정에 실패하였습니다. ({message})",
    en: "Failed to edit comment. ({message})",
  },
  EDIT_DIALOG_HEADER: {
    ko: "Edit Comment",
    en: "Edit Comment",
  },
  EDIT_DIALOG_BTN_CONFIRM: {
    ko: "Save",
    en: "Save",
  },
};

MvnCodeInitiate(STUDY_COMMENT_LANG);
